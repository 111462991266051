<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {
  axiosApi
} from '@/services/axios';

import moment from 'moment';

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  async created() {
    this.loading = true
    // await new Promise(resolve => setTimeout(resolve, 5500));
    axiosApi.get(`/activity/campaign/${this.$route.query.id}`).then(response => {
      response.forEach((item, index) => {
        let actData = {}
        actData.icon = this.activityIconMap(item.action)
        actData.date = moment(item.created_at).format("D MMM")
        actData.align = index % 2 == 0 ? "left" : "right"// same side if on the same day, opposite every other day
        actData.title = item.cmp_act_title
        actData.text = item.body
        actData.images = [item.img]
        actData.url = item.cmp_act_url
        this.timelineData.push(actData)
      });
      this.loading = false
    })
    axiosApi.get(`/campaigns/${this.$route.query.id}`).then(response => {
      this.campaignData = response
      this.loading = false
    })
  },
  data() {
    return {
      title: `Campaign • ${this.$route.query.title}`,
      items: [
        {
          text: "99subs"
        },
        {
          text: "Campaigns",
          active: true
        },
      ],
      loading: false,
      campaignData: null,
      timelineData: [],
      playlistData: []
    };
  },
  methods: {
    /**
     * Selected image shows
     */
    imageShow(event) {
      const image = event.target.src;
      const expandImg = document.getElementById("expandedImg");
      expandImg.src = image;
    }
  }
};
</script>

<template>
<Layout>
  <PageHeader
    :title="title"
    :items="items"
  />
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-4">
              <div class="product-detail">
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div
                      class="tab-content mb-3"
                      id="v-pills-tabContent"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="product-1"
                        role="tabpanel"
                      >
                        <div class="product-img">
                          <img
                            v-if="campaignData && campaignData.img"
                            id="expandedImg"
                            :src="campaignData.img"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </div>
                    </div>
                    <h5 class="text-center">Now Streaming On</h5>
                    <div class="row text-center">
                      <div class="col-md-12">
                        <div v-if="campaignData && campaignData.playlists_accept" class="product-color">
                          <a v-for="(playlist, index) in campaignData.playlists_accept" :key="index" :href="playlist.external_urls.spotify" target="_blank">
                            <div class="product-color-item">
                              <img
                                :src="playlist.images[0].url"
                                :alt="playlist.name"
                                class="avatar-xl"
                              />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end product img -->
            </div>
            <div class="col-xl-8">
              <div class="mt-4 mt-xl-3">
                <!-- <h5 class="mt-1 mb-3">Stats</h5>
                  <div class="row">

                  </div> -->

                <!-- timeline -->
                <div class="row justify-content-center">
                  <div class="col-xl-10">
                    <div
                      class="timeline"
                      dir="ltr"
                    >
                      <div class="timeline-item timeline-left">
                        <div class="timeline-block">
                          <div class="time-show-btn mt-0">
                            <a
                              href="#"
                              class="btn btn-danger btn-rounded w-lg"
                            >2022</a>
                          </div>
                        </div>
                      </div>

                      <div
                        class="timeline-item"
                        v-for="(data, index) in timelineData"
                        :key="index"
                        :class="{'timeline-left': `${data.align}` === 'left'}"
                      >
                        <div class="timeline-block">
                          <div class="timeline-box card">
                            <div class="card-body">
                              <span class="timeline-icon"></span>
                              <div class="timeline-date">
                                <i class="mdi mdi-circle-medium circle-dot"></i>
                                {{data.date}}
                              </div>
                              <h5 class="mt-3 foont-size-15" v-html="data.title"></h5>
                              <div class="text-muted">
                                <p class="mb-0" v-html="data.text"></p>
                              </div>
                              <div
                                class="timeline-album"
                                v-if="data.images"
                              >
                                <a
                                  :href="data.url"
                                  class="mr-1"
                                >
                                  <img
                                    :src="`${data.images[0]}`"
                                    alt
                                  />
                                </a>
                                <a
                                  href="#"
                                  class="mr-1"
                                >
                                  <img
                                    :src="`${data.images[1]}`"
                                    alt
                                  />
                                </a>
                                <a
                                  href="#"
                                  class="mr-1"
                                >
                                  <img
                                    :src="`${data.images[2]}`"
                                    alt
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end row -->

          <!-- <div class="mt-4">
            <h5 class="font-size-14">Feedback :</h5>
            <div class="d-inline-flex mb-3">
              <div class="text-muted mr-3">
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star text-warning"></span>
                <span class="mdi mdi-star"></span>
              </div>
              <div class="text-muted">( 132 curator Review)</div>
            </div>
            <div class="border p-4 rounded">
              <div class="media border-bottom pb-3">
                <div class="media-body">
                  <p class="text-muted mb-2">To an English person, it will seem like simplified English, as a skeptical Cambridge</p>
                  <h5 class="font-size-15 mb-3">James</h5>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                      </a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">11 Feb, 2020</p>
              </div>
              <div class="media border-bottom py-3">
                <div class="media-body">
                  <p class="text-muted mb-2">Everyone realizes why a new common language would be desirable</p>
                  <h5 class="font-size-15 mb-3">David</h5>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                      </a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">22 Jan, 2020</p>
              </div>
              <div class="media py-3">
                <div class="media-body">
                  <p class="text-muted mb-2">If several languages coalesce, the grammar of the resulting</p>
                  <h5 class="font-size-15 mb-3">Scott</h5>

                  <ul class="list-inline product-review-link mb-0">
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-thumb-up align-middle mr-1"></i> Like
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="mdi mdi-message-text align-middle mr-1"></i> Comment
                      </a>
                    </li>
                  </ul>
                </div>
                <p class="float-sm-right font-size-12">04 Jan, 2020</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- end card -->
    </div>
  </div>
  <!-- end row -->

  <!-- values-banner -->
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="media">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Free Shipping</h5>
                  <p class="text-muted mb-0">Sed ut perspiciatis unde</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="media mt-4 mt-md-0">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-exchange-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Easy Return</h5>
                  <p class="text-muted mb-0">Neque porro quisquam est</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="media mt-4 mt-md-0">
                <div class="avatar-sm mr-3">
                  <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                    <i class="ri-money-dollar-circle-line"></i>
                  </span>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <h5>Cash on Delivery</h5>
                  <p class="text-muted mb-0">Ut enim ad minima quis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- end row -->
</Layout>
</template>

<style scoped>
  .avatar-xl {
    height: 6rem;
    width: 6rem;
  }
  a {
    cursor: pointer !important;
  }
</style>
